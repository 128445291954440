 

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 12, 2021 */



 @font-face {
  font-family: 'yatra_oneregular';
  src: url('./fonts/yatraone-regular-webfont.woff2') format('woff2'),
       url('./fonts/yatraone-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'Montserrat-bold';
  src: url('./fonts/montserrat-bold-webfont.woff2') format('woff2'),
       url('./fonts/montserrat-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/montserrat-regular-webfont.woff2') format('woff2'),
       url('./fonts/montserrat-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'Open-Sans-bold';
  src: url('./fonts/opensans-bold-webfont.woff2') format('woff2'),
       url('./fonts/opensans-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'Open-Sans';
  src: url('./fonts/opensans-regular-webfont.woff2') format('woff2'),
       url('./fonts/opensans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'Roboto-bold';
  src: url('./fonts/roboto-bold-webfont.woff2') format('woff2'),
       url('./fonts/roboto-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto-regular-webfont.woff2') format('woff2'),
       url('./fonts/roboto-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'PT Serif-Italic';
  src: url('./fonts/ptserif-italic-webfont.woff2') format('woff2'),
       url('./fonts/ptserif-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'PT Serif';
  src: url('./fonts/ptserif-regular-webfont.woff2') format('woff2'),
       url('./fonts/ptserif-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'Oswald';
  src: url('./fonts/oswald-variablefont_wght-webfont.woff2') format('woff2'),
       url('./fonts/oswald-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'DM-Sansbold';
  src: url('./fonts/dmsans-bold-webfont.woff2') format('woff2'),
       url('./fonts/dmsans-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'DM-Sans';
  src: url('./fonts/dmsans-regular-webfont.woff2') format('woff2'),
       url('./fonts/dmsans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

html {
     scroll-behavior: smooth;
    
   }
div.all{
     min-height: calc(100vh - 547px);
}

#downloads h2 {
     font-family: PT Serif;
     font-style: normal;
     font-weight: normal;
     font-size: 20px;
     line-height: 24px;
     color: #000000;
     text-align: center;
   }
   div.download-section {
     display: flex;
     /* justify-content: space-evenly; */
     margin-top: 41px;
     margin-bottom: 40px;
     justify-content: space-between;
     max-width: 650px;
     border: 1px solid #b2beb5;
     border-radius: 12px;
     padding: 12px 14px;
     margin-left: auto;
     margin-right: auto;
   }
   a.down {
     display: flex;
     flex-direction: column;
     align-items: center;
     color: #a09d9c;
   }
   