
.slick-prev {
  width: 52px;
  height: 52px;
  font-size:25px !important;
  margin-left: 40px !important;
  font-size: 30px !important;
  transform: none !important;
  z-index: 1 !important;
  left: 3px !important;
}

.slick-next {
  width: 52px;
  height: 52px;
  font-size: 30px !important;
  margin-right: 40px !important;
  transform: none !important;
  z-index: 1 !important;
  right: 3px !important;
}
#News .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: unset !important;
}

.slick-dots {
  text-align: right !important;
  
}
.MuiTypography-root{
  width: 100%;
}
#Faculty {
  margin-top: 38px !important;
  transform: rotate("180") !important;
  
}

#Faculty #panel1a-header .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate("180") !important;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: #3d352c;
  color: white;
  min-width: 160px;
  z-index: 1;
  top: 46px;
}
.dropdown-content span {
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
}
.dropdown-content:hover {
  display: block;
}

.dropdown-content a {
  text-decoration: none;
  display: block;
  color: #fff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 17px;
  padding:10px 16px;
}
.dropdown-content a.padding{
  padding: 10px 20px 10px 16px;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content a:hover {
  color: #e90101;
}
.dropdownresearch {
  position: relative;
  display: inline-block;
  width: 100%;
}
.dropdownresearch:hover .dropdown-contentresearch {
  display: block;
}
.dropdown-contentresearch{
  display: none;
  position: absolute;
  background: #3d352c;
  color: white;
  min-width: 160px;
  z-index: 1;
  left: 159px;
  top:-17px;
}
.dropdown-contentresearch a{
  padding-right: 0;
}

@media screen and (max-width:765px){
  
  #Faculty .MuiAccordionSummary-root{
      flex-direction:column;
  }
}


.MuiDrawer-paper{
  width:100%;
  background-color: #E5E5E5 !important;
}


@media(max-width:744px){
  .slick-dots {
    text-align: right !important;
    width:90% !important ;
  }
}

@media(max-width:380px){
  .slick-dots {
    text-align: right !important;
    width:95% !important ;
  }
}
.MuiTableCell-root{
  font-size: 15px !important;
}
.MuiTableCell-head{
  font-weight: 600 !important;
}